import { useMemo } from 'react';
import classNames from 'classnames';
import { ColumnDef } from '@tanstack/react-table';
import { AnchorLink, Heading, TextBox } from '@/components/1-atoms';
import { ContentCard, DataTable, Dropdown } from '@/components/2-molecules';
import styles from './LeadsOverview.module.scss';
import { ReactComponent as MenuIcon } from '@/assets/Icons/icon-menu.svg';
import { useNavigate } from 'react-router-dom';

export interface LeadsOverviewProps {
  className?: string;
  leadsList?: LeadOverview;
}

export const LeadsOverview = ({ className, leadsList }: LeadsOverviewProps) => {
  const navigate = useNavigate();

  const handleOptionClicked = (value: string) => {
    console.log(value);
  };

  const getMenuOptions = (lead: Lead) => {
    return [
      { value: 'viewLead', label: 'View lead' },
      { value: 'removeLead', label: 'I’m not interested' },
    ];
  };

  const columns = useMemo<ColumnDef<Lead>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'TYPE',
        accessorFn: (row) => row.type?.name,
      },
      {
        header: 'START DATE',
        accessorFn: (row) => row.startDate?.split('T')[0],
      },
      {
        header: 'TURNOVER',
        accessorFn: (row) => row.details?.turnover,
      },

      {
        header: 'ACTIONS',
        cell: (table: any) => {
          const lead = table.row.original;

          return (
            <Dropdown
              className={styles.LeadsOverview__actionsButton}
              options={getMenuOptions(lead)}
              onChange={handleOptionClicked}
            >
              <MenuIcon />
            </Dropdown>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className={classNames(styles.LeadsOverview, className)}>
      <ContentCard
        heading={<Heading headingLevel={'h2'}>Leads overview</Heading>}
        actionButton={<div className={styles.LeadsOverview__filters}></div>}
      >
        <DataTable
          columns={columns}
          data={leadsList?.leads || []}
          onRowClick={(lead) => navigate(lead.id.toString())}
        />
        
        {!leadsList?.leads?.length && (

          <div className={styles.LeadsOverview__emptyList}>
            <TextBox
              title={
                <Heading headingLevel={'h3'} className={styles.LeadsOverview__emptyListHeading}>
                  There are no leads for this company.
                </Heading>
              }
              description={
                <>
                  Not sure how to get started? Try{' '}
                  <AnchorLink link={''}>
                    <>
                      watching our video <br /> guide
                    </>
                  </AnchorLink>{' '}
                  or <AnchorLink link={''}> send us a question. </AnchorLink>
                </>
              }
              alignment={'centre'}
            />
          </div>
        )}
      </ContentCard>
    </div>
  );
};
