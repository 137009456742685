import Api from '../axiosApi';

export const CreateTender = (
  tender: TenderForm,
  contactId: number,
  organisationId: number,
): Promise<{ tenderId: number }> => {
  return Api().post('Tender/TenderCreate', {
    contactId: contactId,
    organisationId: organisationId,
    ...tender,
  });
};

export const UpdateTender = (
  tender: TenderForm,
  contactId: number,
  organisationId: number,
): Promise<{ successfulOperation: boolean }> => {
  const id = tender.tenderId;
  const { tenderPhases, ...tenderData } = tender;
  return Api().post(`Tender/TenderUpdate?tenderId=${id}`, {
    contactId: contactId,
    organisationId: organisationId,
    ...tenderData,
  });
};

export const CreateTenderPhase = (
  phase: TenderFormPhase,
  tenderId: number,
): Promise<{ phaseId: number }> => {
  return Api().post('Phase/PhaseCreate', {
    tenderId: tenderId,
    ...phase,
  });
};

export const UpdateTenderPhase = (
  phase: TenderFormPhase,
  tenderId: number,
): Promise<{ phaseId: number }> => {
  const id = phase.phaseId;
  return Api().post(`Phase/PhaseUpdate?phaseId=${id}`, {
    tenderId: tenderId,
    ...phase,
  });
};

export const DeleteTenderPhase = (phaseId: number): Promise<{ successfulOperation: boolean }> => {
  return Api().get('Phase/PhaseDelete', {
    params: {
      phaseId: phaseId,
    },
  });
};

export const CreateTenderQuestions = (
  tenderQuestions: {
    phaseId: number | undefined;
    headline: string;
    content: string;
    selectedCategoryId: number;
    selectedAcceptanceOptionId: number;
  }[],
): Promise<{ successfulOperation: boolean }> => {
  return Api().post('PhaseQuestion/CreateQuestions', tenderQuestions);
};

export const GetBuyerTender = (
  tenderId: number,
): Promise<{
  tender: TenderDetails;
  participants: TenderParticipants[];
}> => {
  return Api().get('Tender/GetBuyerTender', {
    params: {
      tenderId,
    },
  });
};

export const getSupplierTender = (tenderId: number): Promise<TenderDetails> => {
  return Api().get('/Tender/GetSupplierTender', {
    params: {
      tenderId,
    },
  });
};

export const participateInTender = (
  orgId: number,
  tenderId: number,
): Promise<{ successfulOperation: boolean }> => {
  return Api().post('Tender/ParticipateInTender', {
    orgId: orgId,
    tenderId: tenderId,
  });
};

export const UploadPhaseDocument = (formData: FormData): Promise<number> => {
  return Api().post('Document/UploadDocument', formData);
};

export const fetchPhaseDocument = (documentId: number): Promise<Blob> => {
  return Api().get('Document/FetchDocument', {
    params: {
      documentId: documentId,
    },
  });
};

// TODO: CreatePhaseQuestions, DeletePhaseQuestion
