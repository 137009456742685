import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { CreateOrganisation, UpdateOrganisation } from '@/services/OrganisationServices';

import styles from './CreateCompanyForm.module.scss';
import { Heading } from '@/components/1-atoms';
import { FormFieldInput, FormFieldCta, FormFieldRadio } from '@/components/2-molecules';

import { ReactComponent as BuildingIcon } from '@/assets/Icons/icon-building.svg';
import { ReactComponent as CompanyDetailsIcon } from '@/assets/Icons/icon-company-details.svg';
import { ReactComponent as FactoryIcon } from '@/assets/Icons/icon-factory.svg';
import { ReactComponent as PersonProfileIcon } from '@/assets/Icons/icon-person-profile.svg';
import { ReactComponent as MailIcon } from '@/assets/Icons/icon-mail.svg';
import { ReactComponent as PhoneIcon } from '@/assets/Icons/icon-phone.svg';
import { ReactComponent as AddressIcon } from '@/assets/Icons/icon-address.svg';
import { ReactComponent as PersonsIcon } from '@/assets/Icons/icon-persons.svg';
import { ReactComponent as CoinsIcon } from '@/assets/Icons/icon-coins.svg';

interface CreateCompanyFormProps {
  organisation?: OrganisationDetails;
  onCancel: () => void;
  onSubmitSuccess: () => void;
}

export const CreateCompanyForm = ({
  organisation,
  onCancel,
  onSubmitSuccess,
}: CreateCompanyFormProps) => {
  const navigate = useNavigate();
  const loginProfileId = useAppSelector((state) => state.supplierProfile?.userId);
  const contactId = useAppSelector((state) => state.supplierProfile?.settingsOverview?.contact?.id);

  const {
    register,
    control,
    handleSubmit: formSubmit,
    setValue,
  } = useForm<OrganisationDetails>({
    defaultValues: organisation || {},
  });

  const isEditMode = !!organisation;

  useEffect(() => {
    if (organisation) {
      Object.entries(organisation).forEach(([key, value]) => {
        setValue(key as keyof OrganisationDetails, value as string | number);
      });
    }
  }, [organisation, setValue]);

  const handleFormSubmit: SubmitHandler<OrganisationDetails> = async (data) => {
    try {
      const userId = loginProfileId ?? 0;
      const contact = { ...data.contact, id: contactId ?? 0 };
      const dataWithUserId = { ...data, userId, contact };

      if (isEditMode) {
        await UpdateOrganisation(dataWithUserId);
      } else {
        const createdOrg = await CreateOrganisation(
          userId,
          data.organisationNo,
          data.organisationType,
        );
        await UpdateOrganisation({ ...dataWithUserId, orgId: createdOrg.orgId });
      }

      onSubmitSuccess();
      navigate('/leads/settings/company');
    } catch (error) {
      console.error('Error during form submission:', error);
    }
  };

  return (
    <section className={styles.CreateCompanyForm}>
      <Heading headingLevel='h2' className={styles.CreateCompanyForm__pageTitle}>
        {isEditMode ? 'Company settings' : 'Add new company'}
      </Heading>

      <form
        className={styles.CreateCompanyForm__form}
        onSubmit={formSubmit(handleFormSubmit)}
        onReset={onCancel}
      >
        {!isEditMode && (
          <Controller
            control={control}
            name='organisationType'
            render={({ field: { onChange, value } }) => (
              <FormFieldRadio
                title='How do you want to use the system?'
                name='organisationType'
                options={[
                  { value: 'Buyer', label: 'Buyer' },
                  { value: 'Supplier', label: 'Supplier' },
                ]}
                optionsGap='20rem'
                defaultSelectedValue={value}
                onChange={onChange}
                state={{ required: true }}
              />
            )}
          />
        )}

        <div className={styles.CreateCompanyForm__row}>
          <FormFieldInput
            id='companyName'
            label='Company name'
            type='text'
            name='companyName'
            register={register}
            icon={<BuildingIcon />}
          />
          <FormFieldInput
            id='organisationNo'
            label='Organisation Number'
            type='text'
            name='organisationNo'
            register={register}
            icon={<CompanyDetailsIcon />}
          />
        </div>

        <div className={styles.CreateCompanyForm__row}>
          <FormFieldInput
            id='industry'
            label='Industry'
            type='text'
            name='industry'
            register={register}
            icon={<FactoryIcon />}
          />
          <FormFieldInput
            id='contact.firstName'
            label='First name'
            type='text'
            name='contact.firstName'
            register={register}
            icon={<PersonProfileIcon />}
          />
        </div>

        <div className={styles.CreateCompanyForm__row}>
          <FormFieldInput
            id='contact.lastName'
            label='Last name'
            type='text'
            name='contact.lastName'
            register={register}
            icon={<PersonProfileIcon />}
          />
          <FormFieldInput
            id='contact.email'
            label='Email'
            type='email'
            name='contact.email'
            register={register}
            icon={<MailIcon />}
          />
        </div>

        <div className={styles.CreateCompanyForm__row}>
          <FormFieldInput
            id='contact.phoneNo'
            label='Phone'
            type='tel'
            name='contact.phoneNo'
            register={register}
            icon={<PhoneIcon />}
          />
          <FormFieldInput
            id='address'
            label='Address'
            type='text'
            name='address'
            register={register}
            icon={<AddressIcon />}
          />
        </div>

        <div className={styles.CreateCompanyForm__row}>
          <FormFieldInput
            id='numberOfEmployees'
            label='Number of employees'
            type='number'
            name='numberOfEmployees'
            register={register}
            icon={<PersonsIcon />}
          />
          <FormFieldInput
            id='turnover'
            label='Turnover'
            type='text'
            name='turnover'
            register={register}
            icon={<CoinsIcon />}
          />
        </div>

        <div className={styles.CreateCompanyForm__cta}>
          <FormFieldCta type='reset' id='cancel' label='Cancel' />
          <FormFieldCta type='submit' id='save' label='Save' />
        </div>
      </form>
    </section>
  );
};
