import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';
import { SettingPage } from '@/components/3-organisms/SettingsOverview/SettingsList/SettingsItem'; // TODO: global model type?
import {
  SettingsList,
  AccountOverview,
  CompanyOverview,
  IntegrationsOverview,
  PricingOverview,
  CreateCompanyForm,
} from '@/components/3-organisms/SettingsOverview';
import { LeadsLayout } from '@/components/4-templates';

import styles from './SettingsOverviewPage.module.scss';
import { useCustomerProfile } from '@/hooks/useCustomerProfile';

export const SettingsOverviewPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { dispatchCustomerProfile } = useCustomerProfile();

  const settingOverview = useAppSelector((state) => state.supplierProfile?.settingsOverview);
  const { contact, organisations } = settingOverview || {};
  const { firstName, lastName, email, phoneNo } = contact || {};

  useEffect(() => {
    dispatch(setTitle('settings'));
  }, [dispatch]);

  const handleCancel = () => {
    navigate('/leads/settings/company');
  };

  const handleFormSubmitSuccess = async () => {
    try {
      await dispatchCustomerProfile();
    } catch (error) {
      console.error('Error fetching updated supplier profile:', error);
    }
  };

  const settingsPages: SettingPage[] = [
    { title: 'Account', href: 'account', subTitle: 'Manage your profile' },
    { title: 'Company', href: 'company', subTitle: 'Manage your profile' },
    {
      title: 'Integrations',
      href: 'integrations',
      subTitle: 'Manage your profile',
    },
    {
      title: 'Pricing plan',
      href: 'pricing-plan',
      subTitle: 'Manage your profile',
    },
  ];

  return (
    <LeadsLayout>
      <section className={styles.SettingsOverviewPage}>
        <div className={styles.SettingsOverviewPage__settingsList}>
          <SettingsList settingsPages={settingsPages} />
        </div>
        <div className={styles.SettingsOverviewPage__settingPage}>
          <Routes>
            <Route path='/' element={<Navigate to={'account'} />} />
            <Route
              path='account'
              element={
                <AccountOverview email={email} name={firstName + ' ' + lastName} phone={phoneNo} />
              }
            />
            <Route path='company' element={<CompanyOverview organisations={organisations} />} />
            
            <Route
              path='/company/create'
              element={
                <CreateCompanyForm
                  onCancel={handleCancel}
                  onSubmitSuccess={handleFormSubmitSuccess}
                />
              }
            />
            <Route
              path='company/update-company'
              element={
                <CreateCompanyForm
                  onCancel={handleCancel}
                  organisation={
                    (location.state as { organisation?: OrganisationDetails })?.organisation
                  }
                  onSubmitSuccess={handleFormSubmitSuccess}
                />
              }
            />

            <Route path='integrations' element={<IntegrationsOverview />} />
            <Route path='pricing-plan' element={<PricingOverview />} />
          </Routes>
        </div>
      </section>
    </LeadsLayout>
  );
};
